import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { IContactUsResponse } from '../../../modules/home/interfaces/contact-us';
import { ContactUsService } from '../../../modules/home/services/contact-us.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-social-media-links',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './social-media-links.component.html',
  styleUrl: './social-media-links.component.scss'
})
export class SocialMediaLinksComponent implements OnInit, OnDestroy {

  public socialInfo!: IContactUsResponse[];
  public isLoading = false;

  private _ContactService = inject(ContactUsService);
  private _Subscription = new Subscription();

  ngOnInit(): void {
    this.getSocialInfo();
  }

  getSocialInfo() {
    this.isLoading = true;
    this._Subscription.add(
      this._ContactService.getSocialInfo().subscribe({
        next: (res) => {
          this.isLoading  = false;
          this.socialInfo = res;
        },
        error: (err) => {
          this.isLoading = false;
          // console.log('err: ', err);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._Subscription.unsubscribe();
  }

}
