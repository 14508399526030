import { Routes } from '@angular/router';
import { emailGuard } from './modules/authentication/guards/email.guard';
import { twofaAuthGuard } from './modules/authentication/guards/twofa-auth.guard';
import { authGuard } from './modules/authentication/guards/auth.guard';
// components
import { NavFooterLayoutComponent } from './layouts/pages/nav-footer-layout/nav-footer-layout.component';
import { FullContentLayoutComponent } from './layouts/pages/full-content-layout/full-content-layout.component';
import { NoNavbarComponent } from './layouts/pages/no-navbar/no-navbar.component';

export const routes: Routes = [

  {
    path: '',
    canActivate: [emailGuard, twofaAuthGuard],
    component: NavFooterLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
      },

    ]
  },
  {
    path: 'tickets',
    component: NavFooterLayoutComponent,
    children: [
      {
        path: '',
        loadComponent: () => import('./modules/tickets/components/my-tickets/my-tickets.component').then(m => m.MyTicketsComponent),
        title: "My Tickets"
      },
      {
        path: 'add-ticket/:id/:orderId',
        canActivate: [authGuard],
        loadComponent: () => import('./modules/tickets/components/add-ticket/add-ticket.component').then(m => m.AddTicketComponent),
        title: "Add New Ticket"
      },
      {
        path: 'ticket-details/:id',
        canActivate: [authGuard],
        loadComponent: () => import('./modules/tickets/components/ticket-details/ticket-details.component').then(m => m.TicketDetailsComponent),
        title: "Ticket Details"
      },
    ]
  },
  {
    path: 'user',
    canActivate: [emailGuard, twofaAuthGuard, authGuard],
    component: NavFooterLayoutComponent,
    children: [
      {
        path: 'profile',
        loadComponent: () => import('./modules/user/components/profile/profile.component').then(c => c.ProfileComponent),
        title: 'User Profile'
      },
      {
        path: 'change-password',
        loadComponent: () => import('./modules/user/components/change-password/change-password.component').then(c => c.ChangePasswordComponent),
        title: 'Change Password'
      },
      {
        path: 'dashboard',
        loadComponent: () => import('./modules/user/components/dashboard/dashboard.component').then(c => c.DashboardComponent),
        title: 'Dashboard'
      },
      {
        path: 'twofa-enable',
        loadComponent: () => import('./modules/user/components/twofa-enable/twofa-enable.component').then(c => c.TwofaEnableComponent),
        title: '2FA Settings'
      }
    ]
  },
  {
    path: 'auth',
    component: FullContentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import("./modules/authentication/authentication.module").then((m) => m.AuthenticationModule),
      },
    ],
  },
  {
    path: 'exchange',
    canActivate: [emailGuard, twofaAuthGuard],
    component: NavFooterLayoutComponent,
    children: [
      {
        path: '',
        loadComponent: () => import('./modules/exchange-direction/component/transactions-history/transactions-history.component').then(m => m.TransactionsHistoryComponent),
        title: 'Exchange History'
      },
      {
        path: 'preview/:id',
        loadComponent: () => import('./modules/exchange-direction/component/exchange-preview/exchange-preview.component').then(m => m.ExchangePreviewComponent),
        title: 'Exchange Preview'
      },
      {
        path: 'confirm-transaction/:id',
        loadComponent: () => import('./modules/exchange-direction/component/verify-exchange-transaction/verify-exchange-transaction.component').then(m => m.VerifyExchangeTransactionComponent),
        title: 'Verify Exchange Transaction'
      },
      {
        path: 'details/:id',
        loadComponent: () => import('./modules/exchange-direction/component/exchange-details/exchange-details.component').then(m => m.ExchangeDetailsComponent),
        title: 'Exchange Details'
      }
    ]
  },
  {
    path: 'wallets',
    component: NavFooterLayoutComponent,
    canActivate: [emailGuard, twofaAuthGuard, authGuard],
    children: [
      {
        path: 'all',
        loadComponent: () => import('./modules/wallets/components/wallets/wallets.component').then(m => m.WalletsComponent),
        title: 'Wallets'
      },
      {
        path: 'topup/preview/:amount/:currencyId',
        loadComponent: () => import('./modules/wallets/topup/components/topup-preview/topup-preview.component').then(m => m.TopupPreviewComponent),
        title: 'Top Up Preview'
      },
      {
        path: 'topup/verify/:topup_id',
        loadComponent: () => import('./modules/wallets/topup/components/topup-verify/topup-verify.component').then(m => m.TopupVerifyComponent),
        title: 'Top Up Verify'
      },
      {
        path: 'topup/list',
        loadComponent: () => import('./modules/wallets/topup/components/topup-list/topup-list.component').then(m => m.TopupListComponent),
        title: 'Top Up List'
      },
      {
        path: 'topup/details/:topUpId',
        loadComponent: () => import('./modules/wallets/topup/components/topup-details/topup-details.component').then(m => m.TopupDetailsComponent),
        title: 'Top Up Details'
      },
      { path: '', redirectTo: 'all', pathMatch: 'full' },
    ],
  },
  {
    path: 'withdrawal',
    component: NavFooterLayoutComponent,
    canActivate: [emailGuard, twofaAuthGuard, authGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('./modules/wallets/withdraw/components/withdraw-balance/withdraw-balance.component').then(m => m.WithdrawBalanceComponent),
        title: "Withdraw Balance"
      },
      {
        path: 'preview/:id',
        loadComponent: () => import('./modules/wallets/withdraw/components/withdraw-preview/withdraw-preview.component').then(m => m.WithdrawPreviewComponent),
        title: "Withdraw Preview"
      },
      {
        path: 'details/:id',
        loadComponent: () => import('./modules/wallets/withdraw/components/withdraw-details/withdraw-details.component').then(m => m.WithdrawDetailsComponent),
        title: "Withdraw Details"
      },
      {
        path: 'list',
        loadComponent: () => import('./modules/wallets/withdraw/components/withdraw-list/withdraw-list.component').then(m => m.WithdrawListComponent),
        title: "Withdraw List"
      }
    ],
  },
  {
    path: 'maintainance',
    component: NoNavbarComponent,
    children: [
      {
        path: '',
        loadComponent: () => import("./modules/authentication/components/maintainance/maintainance.component").then(m => m.MaintainanceComponent),
        title: 'Maintainance Mode'
      },
    ]
  },
  {
    path: 'operation-message',
    component: NavFooterLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import("./modules/operation-message/operation-message.module").then((m) => m.OperationMessageModule),
      },
    ]
  },
  {
    path: '**',
    component: NavFooterLayoutComponent,
    children: [
      { path: '', redirectTo: '', pathMatch: 'full' },
      {
        path: '',
        loadComponent: () => import("./layouts/components/not-found/not-found.component").then((m) => m.NotFoundComponent),
        title: 'Page Not Found'
      },
    ],
  },



];
