<section class="full-content-layout">
  <div class="container h-100">
    <p-toast></p-toast>
    <p class="auth-nav pt-5 text-uppercase fs-4 fw-bold">
      <a routerLink="/" class="logo">
        <img src="https://money-exchange-app.s3.eu-central-1.amazonaws.com/currencies/02a23c12-e7ec-4b8f-8dfa-5e49030e2cdd.webp" alt="app logo" width="200px" height="72px" />
      </a>
    </p>

    <div class="row align-items-center justify-content-center parent">
      <div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>
