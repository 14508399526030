import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ICloseTicketResponse, ICreateTicketResponse, ISendMessageRequest, ISendMessageResponse, ITicketDetailsResponse, ITicketRequest, ITicketResponse } from '../interfaces/tickets';

@Injectable({
  providedIn: 'root'
})

export class TicketsService {

  private _HttpClient = inject(HttpClient);

  // get all tickets with pagination
  getTicketsWithPagination(page: number, limit: number, status: string | null = null, orderId?: string, ticketNumber?: string): Observable<ITicketResponse> {
    let query: string = ``;

    // if there is status put it in query
    status && (query += `&status=${status}`)
    orderId && (query += `&orderId=${orderId}`)
    ticketNumber && (query += `&ticketNumber=${ticketNumber}`)

    return this._HttpClient.get<ITicketResponse>(`${environment.endPointUrl}/v1/ticket/tickets?page=${page}&limit=${limit}${query}`);
  }

  // create ticket By [Exchange_Id]
  createTicket(ticketForm: ITicketRequest): Observable<ICreateTicketResponse> {
    return this._HttpClient.post<ICreateTicketResponse>(`${environment.endPointUrl}/v1/ticket/create-ticket`, ticketForm);
  }

  // get Ticket Details By [Exchange_Id]
  getTicketDetails(page: number, limit: number, id: string): Observable<ITicketDetailsResponse> {
    return this._HttpClient.get<ITicketDetailsResponse>(`${environment.endPointUrl}/v1/ticket/messages/${id}/?page=${page}&limit=${limit}`);
  }

  sendMessage(formData: ISendMessageRequest): Observable<ISendMessageResponse> {
    return this._HttpClient.post<ISendMessageResponse>(`${environment.endPointUrl}/v1/ticket/send-message`, formData)
  }

  // close ticket
  closeTicket(id: string): Observable<ICloseTicketResponse> {
    return this._HttpClient.patch<ICloseTicketResponse>(`${environment.endPointUrl}/v1/ticket/close-ticket/${id}`, {});
  }



}
